
import {defineComponent} from 'vue';
import NodeForm from '@/components/node/NodeForm.vue';

export default defineComponent({
  name: 'NodeDetailTabOverview',
  components: {
    NodeForm,
  },
  setup() {
    return {};
  },
});
